<template>
    <div>
        <div class="content-box">
            <el-form :model="form" ref="form" :inline="true" :label-position="$config.labelPosition"
                :label-width="$config.labelWidth">
                <!-- 基本工资 -->
                <div class="form-label-base">基本工资</div>
                <el-form-item label="出勤天数">
                    <el-input v-model="form.dutyDays" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="标准工资">
                    <el-input v-model="form.wageStandard" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="平时加班(小时)">
                    <el-input v-model="form.usualOvertimeHours" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="平时加班(工资)">
                    <el-input v-model="form.usualOvertimeWage" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="休息日加班(小时)">
                    <el-input v-model="form.weekendOvertimeHours" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="休息日加班(工资)">
                    <el-input v-model="form.weekendOvertimeWage" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="节假日加班(小时)">
                    <el-input v-model="form.holidayOvertimeHours" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="节假日加班(工资)">
                    <el-input v-model="form.holidayOvertimeWage" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="工资小计">
                    <el-input v-model="form.wageSummary" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>    

                <div class="form-label-base">福利</div>
                <el-form-item label="岗位津贴">
                    <el-input v-model="form.welfareAllowance" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="补贴">
                    <el-input v-model="form.welfareSubsidy" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item> 
                <el-form-item label="高温费">
                    <el-input v-model="form.welfareSummer" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item> 
                <el-form-item label="小计">
                    <el-input v-model="form.welfareSummary" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item> 
                <el-form-item label="应发工资">
                    <el-input v-model="form.wageShoud" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>      

                <div class="form-label-base">扣款</div>
                <el-form-item label="社保">
                    <el-input v-model="form.deductionSocialSecurity" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="意外险">
                    <el-input v-model="form.deductionCasualty" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item> 
                <el-form-item label="雇主险">
                    <el-input v-model="form.deductionEmployer" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item> 
                <el-form-item label="个税">
                    <el-input v-model="form.deductionIncomeTax" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item> 
                <el-form-item label="住房公积金">
                    <el-input v-model="form.deductionHaf" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="合计">
                    <el-input v-model="form.deductionSummary" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>

                <div class="form-label-base">实发工资</div>
                <el-form-item label="实发工资">
                    <el-input v-model="form.wageReal" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="form.salaryRemark" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>             
            </el-form>
            <div class="tac" style="margin-top: 40px">
                <el-button type="success" class="btn-success-hw" @click="onSubmit">确定</el-button>
                <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            name: edit-wage,
            dataFromDialog: {
                //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
                type: Object,
                default () {
                    return {};
                }
            }
        },
        data() {
            return {
                nav: [{
                    name: "工资明细",
                }],
                allTableData: [],
                newTableData: [],
                //newAllTableData: [],
                submitLoading: false,
                form: {
                    dutyDays: null,    //出勤天数
                    wageStandard: null,   //标准工资
                    usualOvertimeHours: null,    //平均加班小时
                    usualOvertimeWage: null,     //平均加班工资
                    weekendOvertimeHours: null,    //休息日加班小时
                    weekendOvertimeWage: null,     //休息日加班工资
                    holidayOvertimeHours: null,    //节假日加班小时
                    holidayOvertimeWage: null,     //节假日加班工资
                    wageSummary: null,             //工资小计
                    welfareAllowance: null,       //岗位津贴
                    welfareSubsidy: null,          //补贴
                    welfareSummer: null,        //高温费
                    welfareSummary: null,      //福利小计
                    wageShoud: null,            //应发工资
                    deductionSocialSecurity: null,    //社保
                    deductionCasualty: null,        //意外险
                    deductionEmployer: null,       //雇主险
                    deductionIncomeTax: null,      //个税
                    deductionHaf: null,            //住房公积金
                    deductionSummary: null,       //扣款合计
                    wageReal: null,                //实发工资
                    salaryRemark: null,           //备注
                    personId: null,
                },  
            };
        },
        async mounted() {

        },
        methods: {
            //关闭
            onClose() {
                this.$emit("onClose"); //关闭弹窗
            },
            onSubmit() {
                let _this = this
                _this.$refs.form.validate(async valid => {
                    if (!valid) return;
                    _this.submitLoading = true;
                    try {
                        for(let i = 0; _this.allTableData.length > i; i++){
                            if(_this.allTableData[i].personId === _this.form.personId){
                                Object.assign(_this.allTableData[i],_this.form)
                            }                          
                            _this.newTableData = _this.$_.cloneDeep(_this.allTableData)
                            //console.log(_this.newTableData)
                        }
                        BUS.$emit(
                            BUSEVENT.REFRESH_EDIT_WAGE,
                            _this.newTableData
                        );
                        _this.$emit("onClose"); //关闭弹窗
                    } catch (e) {
                        console.log(e);
                    } finally {
                        _this.submitLoading = false;
                    }
                });
            },
        },
        mounted() {
            this.allTableData = this.dataFromDialog.allTableData;
            for(let j = 0; this.allTableData.length > j; j++){
                if(this.allTableData[j].personId === this.dataFromDialog.personId){
                    this.form = this.allTableData[j]
                }                          
            }
            this.form.personId = this.dataFromDialog.personId;
        },
    };
</script>

<style lang='sass' scoped>
/deep/.content-box .form-label-base
//   border-left: 2px solid $-color-primary-3
  padding-left: 8px
  color: #7E8187
/deep/ .el-button--primary
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-form-item--mini .el-form-item__label
    width: 125px !important
    text-align: right
</style>